import React, { useState } from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import styled, { keyframes } from 'styled-components'
import { BreadCrumb, Section, Tags, ComingSoon } from '../components/Section'
import CaseStudies from "../components/CaseStudies"
import PrimaryLinkButton from "../components/Button/PrimaryLinkButton"
import Benifits from '../components/Benifits'
import OurSolution from "../sections/service/ourSolution"
import BrandsWeTrust from "../sections/service/brandWeTrusted"
import CustomerReiview from "../sections/service/customerReview"
import Faq from "../sections/service/faq"
import LetastArticles from "../components/LetastArticles"
import GetStarted from '../components/GetStarted'
import BreakpointDown from '../components/Media/BreakpointDown'
import BreakpointUp from "../components/Media/BreakpointUp"
import { StaticImage } from "gatsby-plugin-image"
import PlusIcon from '../components/Icons/PlusIcon'
import DotteLineArrowIcon from '../components/Icons/DotteLineArrowIcon';
import SecondaryLinkButton from "../components/Button/SecondaryLinkButton"


const MonitoringSolutions = styled.div`
  display:flex;
  margin-left:-15px;
  margin-right:-15px;
  flex-wrap:wrap;
  min-height:800px;
  padding-bottom: 44px;
  position:relative;
  flex-direction:wrap;
  ${BreakpointDown.xl` 
    padding-bottom:50px;
    min-height:auto;
  `}
  ${BreakpointDown.lg`
      padding-bottom:20px;
  `}
   ${BreakpointDown.md`
      padding-bottom:20px;
  `}
`
const MonitoringSolutionsItem = styled.div` 
  flex:0 0 50%;
  width:50%;
  padding-left:15px;
  padding-right:15px;
  position:relative;
  &:last-child{
    display:none;
   ${BreakpointUp.xl`
     padding-left:0;
     display:block;
   `}
  }
&:first-child{
  padding-right:75px;
  padding-top:210px;
  ${BreakpointDown.xl` 
    padding-top:120px;
    min-height:auto;
  `}
  ${BreakpointDown.lg`
      padding-top:120px;
  `}
   ${BreakpointDown.md`
      padding-top:90px;
  `}
  ${BreakpointDown.md`
    padding-right:15px;
  `}
}
${BreakpointDown.xl` 
  flex:0 0 100%;
  width:100%;
`}
`
const ServiceTitle = styled.h1`
  /* max-width:445px; */
  margin-top:20px;
  margin-bottom:20px;
  line-height:48px;
 ${BreakpointDown.lg`
   line-height:inherit;
 `}
`
const MonitoringSolutionsItemContent = styled.p`
font-size:16px;
`
const MonitoringSolutionsBottomWrapper = styled.div`
 position:relative;
`

const MonitoringSolutionsBottom = styled.div`
  display:flex;
  flex:0 0 100%;
  left:0;
  right:0;
  position:absolute;
  bottom:-60px;
  font-size:20px;
  font-family: 'Comic Neue', cursive;
  z-index:2;
  height:120px;
  flex-wrap:wrap;
  margin-right: 60px;
  margin-left: 60px;
  @media (min-width:2400px){
    margin-left:200px;
    margin-right:200px; 
  }
  @media (max-width: 1024px) {
    margin-right: 0;
    margin-left: 0;
  }

  @media(max-width:1280px){
    bottom:0px; 
  }
  ${BreakpointDown.xl`
    position:static;
  `}
  ${BreakpointDown.md`
    height:auto;
  `}
`
const MonitoringSolutionsBottomLeft = styled.div`
flex: 0 0 50%;
  background:#529A00;
  padding:10px 45px;
  color:#FFFFFF;
  position:relative;
  ${BreakpointDown.lg`
    padding:10px 15px;
  `}
   ${BreakpointDown.md`
    flex: 0 0 100%;
  `}
&:after{
  position:absolute;
  right:-50px;
  top:50%;
  transform:translateY(-50%);
  content:'';
  width:80px;
  height: 2px;
  margin-top: 2px;
  background-image: linear-gradient(90deg, #fff, #fff 75%, transparent 0%, transparent 100%); 
  background-size: 20px 1px;
  border: none;
  ${BreakpointDown.md`
    display:none
  `}
}
`
const InnerText = styled.div`
  display:flex;
  align-items:center;
  height: 100%;
  justify-content:flex-end;
  ${BreakpointDown.md`
      line-height: 27px;
  `}
`
const MonitoringSolutionsBottomRight = styled.div`
  flex:0 0 50%;
  padding:15px 45px;
  background:#000000;
  @media(max-width:1280px){
    flex:0 0 50%;
  }
  ${BreakpointDown.lg`
    padding:15px 15px;
  `}
  ${BreakpointDown.md`
    flex: 0 0 100%;
  `}
`
const BtnWrapper = styled.div`
    display: flex;
    align-items: center;
    height: 100%;
    justify-content:center;
.btn{
  ${BreakpointUp.md`
   margin-left:40px; 
  `}
  padding:0;
  .text{
    color:#fff;
    letter-spacing: 3.2px;
    font-size:16px;
    ${BreakpointDown.lg`
      letter-spacing: 1.1px;
    `}
    &:before{
      background: #529A00;
    }
    &:after{
      background: #fff;
    }
  }
}
`
const ImgWrapper = styled.div`
 max-width:100%;
  height:100%;
 display:block;
 position: absolute;
right:15px;
 .gatsby-image-wrapper{ width:100%; height:100%;}
`
// our services
const OurSolutionTitle = styled.div`
 
`
const HeadingWrapper = styled.div`
 display:flex;
 margin-left:-15px;
 margin-right:-15px;
 flex-wrap:wrap;
 justify-content:space-between;
`
const HeadingItem = styled.div`
  padding-left:15px;
  padding-right:15px;
`
const OurSolutionList = styled.div`
 display:flex;
 flex-wrap:wrap;
 margin-top:40px;
 border-top:1px solid #CCCCCC;
 padding-top:40px;
 ${BreakpointDown.xl`
  padding-top:20px;
  padding-bottom:0;
  margin-top: 20px;
  text-align:center;
 `}
`
const OurSolutionListLeft = styled.div`
flex:0 0 600px;
position:relative;
${BreakpointDown.xl`
flex:0 0 100%;
text
 `}
&:before{
  content:'';
  width:120px;
  height:4px;
  background:#000;
  position:absolute;
  right:0;
  top:35px;
  margin-right:-60px;
  z-index:1;
  ${BreakpointDown.xl`
 display:none;
 `}
}
`
const OurSolutionListRight = styled.div`
flex:1;
padding-left:90px;
padding-top:20px;
${BreakpointDown.xl`
padding-left:0;
 `}
`
const OurSolutionListRightTitle = styled.div`
`
const OurSolutionListRightContent = styled.div`

`
// faq




const FadeIn = keyframes`
 from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const SectionBg = styled(Section)`
  position:relative;
  box-shadow: 0px 20px 85px #00000033;
  z-index: 2;
&:before, &:after{
  width:50%;
  ${BreakpointDown.xl`
    display:none;
    `
  }
}
.container{z-index:1}
`

const FaqTitle = styled.div`
 text-transform:uppercase;
 margin-bottom:0;
`

const FaqRow = styled.div`
 display:flex;
 flex-wrap:wrap;
 justify-content:space-around;
 align-items:center;
`
const FaqColumn = styled.div`
  flex: 0 0 50%;
  width:50%;
  position:relative;
  padding-top:150px;
  padding-bottom:150px;
  
  ${BreakpointDown.xl`
      flex: 0 0 100%;
      width:100%;
  `}
  &:first-child{
    border-right: 1px solid #00000033;
    box-shadow: 1px 0px 2px 0px #00000033;
    background:#fff;
    ${BreakpointDown.xl`
      padding-top:30px;
      padding-bottom:0px;
      border-right:0;
      box-shadow:none;
    `
  }
  }
  &:only-child{
    flex: 0 0 100%;
      width:100%;
      border-right:0;
      box-shadow:none
  }
  &:last-child{
    padding: 105px;
    ${BreakpointDown.xl`
        padding: 30px 0px;
      `
  }
   &:before{
     content:"?";
     font-size:800px;
     font-weight:900;
      position:absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
     font-family: "Nunito Sans", sans-serif;
     text-transform:uppercase;
     letter-spacing: -8px;
     color: #000000;
     opacity: 0.05;
     display:none;
     ${BreakpointDown.xl`
       display:none;
    `
  }
   }
  }
`
const TabList = styled.div` 
`
const TabListDesktop = styled(TabList)`
display:none;
${BreakpointUp.xl`
   display:inherit
`
  }
`
const TabListMobile = styled(TabList)`
display:none;
${BreakpointDown.xl`
   display:inherit
   `
  }
`
const TabListItem = styled.div`
padding-top:20px;
padding-bottom:20px;
display:flex;
justify-content:space-between;
align-items:center;
padding-left:20px;
padding-right:20px;
margin-bottom:0;
position: relative;
font-family: "Nunito Sans", sans-serif;
line-height: 24px;
font-weight: bold;
cursor: pointer;
${BreakpointDown.xl`
  flex-direction:column;
  background:#fff;
  border-bottom: 1px solid #00000033;
  padding-left:15px;
padding-right:15px;
 &:nth-of-type(){
  border-top: 1px solid #00000033;
 }
 `}
 ${BreakpointDown.md`
  padding-right:35px;
 `}

svg{
  transition: all 0.1s linear;
  position:absolute;
  right:0;
  ${BreakpointDown.md`
    right:-15px;
 `}
}
&:before{
    content:'';
    background:#F8F8F8;
    position:absolute;
    left:0;
    right:-40px;
    top:0;
    bottom:0;
    display:none;
    ${BreakpointDown.xl`
     right:0;
    `}
  }
&+&{
  border-top: 1px solid #00000033;
}
&.active{
  >div{
    color:#000;
  }
  svg{
    transform: rotate(45deg);
    path{
      fill:#000;
    }
  }
  .icon{
     display:block;
     ${BreakpointDown.xl`
    display:none;
    `}
  
  }
&:before{
  display:block;
}
}
`
const Text = styled.div`
  position:relative;
  z-index:1;
  display:flex;
  justify-content:space-between;
  align-items:center;
  width: 100%;
  color:#666666;
`
const Icon = styled.div`
  position:absolute;
  right:-30px;
  display:none;
  margin-top: 8px;
  transform: rotate(224deg);

 svg{
   margin-left:15px;
   path{
     fill:#000;
   }
 }
`
const TabContnet = styled.div`
  position:relative;
  z-index:1;
 
`
const TabContentItem = styled.div`
  animation-duration: 1s;
  animation-name: ${FadeIn};
  animation-fill-mode: both;
  transition: opacity 0.15s linear;
  &.tab-item{
    ${BreakpointUp.xl`
    display:none;
    `
  }
    &.active{
      ${BreakpointUp.xl`
        display:inherit;
        `
  }
    .tab-content-inner{
      padding-top:20px;
      height:auto;
      overflow:auto;
    }
    }
  }
`
const TabContentinner = styled.div`
   ${BreakpointDown.xl`
      height:0;
      overflow:hidden;
      transition: height 0.15s linear; 
    `
  }
`
//Letast BLogs


const Card = styled.div`
  padding: 70px 50px ;
  background:#fff;
  box-shadow: 0px 20px 45px rgb(0, 0, 0, 0.20);
  /* @media (min-width:2200px){
    padding: 20px 15px;
  } */
  @media(max-width:1440px){
    padding: 20px 14px; 
  }
  ${BreakpointDown.lg`
   text-align:center;
 `}
  .btn{
    margin-top:15px;
  }
`
const CardTitle = styled.div`
 margin-bottom:6px;
 font-weight:bold;
`
const CardImg = styled.div`
  position: relative;
  max-width:100%;
  width:100%;
  margin-top:26px;
  .gatsby-image-wrapper{
    width:100%;
  }
`
const Date = styled.div`
  color:#666666;
  font-size:10px;
  letter-spacing: 2px;
  text-transform: uppercase;
  @media (min-width:2200px){
    font-size:14px;
  }
`
const SectionHeader = styled.div`
 text-align:center;
 .h1{ margin-bottom:0px;}
 ${BreakpointDown.md`
 p{
   margin-bottom:0;
 } 
 `}
`
const Title = styled.h2`

`

const Blogs = styled.div`
 display: flex;
 margin-inline:-15px;
 flex-wrap:wrap;
 margin-top:50px;
`
const BlogItems = styled.div`
 flex:0 0 auto;
 width:33.3333%;
 padding-inline:15px;
 @media(max-width:767px){
   width:100%;
  & + &{
    margin-top:20px;
  }
 }
`

function ServicePage() {
  const [activeTab, setactiveTab] = useState(0);
  const HandleTabs = (key) => {
    setactiveTab(key);
  };
  return (
    <Layout pageName="service">
      <SEO title="Interactive Smart Board Installation"
    description="Want to install smart boards? WYN Technologies have the best custom interactive whiteboards for organization and institutions of all scale. Get your quote today." />
      <Section bgColor="#F8F8F8" pt="0" pb="0">
        <div className="container">
          <MonitoringSolutions>
            <MonitoringSolutionsItem>
              <BreadCrumb>
                <Link to="/">Home</Link><Link to="/">Services</Link><Link to="/#">Interactive Whiteboard Installation</Link>
              </BreadCrumb>
              <ServiceTitle>Interactive Whiteboard Installation</ServiceTitle>
              <MonitoringSolutionsItemContent>
                Interactive whiteboards – often referred to as interactive smart boards in the educational community – have been a common staple of hands-on, intuitive learning in schools and classrooms across America for years. To that end, WYN Technologies is happy to provide interactive whiteboard installation services for local schools, colleges, universities, and other educational institutions. But the practical applications of interactive whiteboards extend beyond education into the world of business, too. These interactive presentations & learning tools are great assets for retail environments, patient care facilities, offices, boardrooms, conference rooms, and more. WYN can provide interactive whiteboard installation services for your organization, too!
              </MonitoringSolutionsItemContent>
            </MonitoringSolutionsItem>
            <MonitoringSolutionsItem>
              <ImgWrapper>
                <StaticImage
                  src="../images/ips-service.jpg"
                  quality={100}
                  formats={["AUTO", "WEBP", "AVIF"]}
                  alt="Letast Article"
                  placeholder="blurred"
                />
              </ImgWrapper>
            </MonitoringSolutionsItem>
          </MonitoringSolutions>
        </div>
      </Section>
      <MonitoringSolutionsBottomWrapper>
        <div className="container">
          <MonitoringSolutionsBottom>
            <MonitoringSolutionsBottomLeft>
              <InnerText>We ensure that you get the right technologies and best products</InnerText>
            </MonitoringSolutionsBottomLeft>
            <MonitoringSolutionsBottomRight>
              <BtnWrapper>
                <PrimaryLinkButton text="Solution You’re Looking!" />
              </BtnWrapper>
            </MonitoringSolutionsBottomRight>
          </MonitoringSolutionsBottom>
        </div>
      </MonitoringSolutionsBottomWrapper>
      <Benifits />
      <Section bgColor="#F8F8F8" pt="90px" pb="90px" xpt="60px" xpb="60px" mpt="40px" mpb="40px">
        <div className="container">
          <HeadingWrapper>
            <HeadingItem>
              <OurSolutionTitle className="h1">What We Provide with Our Interactive Whiteboard Installation Services</OurSolutionTitle>
              {/* <p>Lorem Ipsum is simply dummy text of the printing and type setting industry.</p> */}
            </HeadingItem>
          </HeadingWrapper>

          <OurSolutionList>
            <OurSolutionListLeft>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </OurSolutionListLeft>
            <OurSolutionListRight>
              <OurSolutionListRightTitle className="h3">Sales, Installation, Product Setup, & Orientation</OurSolutionListRightTitle>
              <OurSolutionListRightContent>
                <p><strong>WYN Technologies</strong> is thrilled to be able to provide cutting-edge interactive whiteboard products from leading brands like Sharp and NEC. But we’re not just out to sell you a product; we’re really here to deliver complete turnkey solutions for you! <strong>WYN</strong> provides start-to-finish installation and setup services; our clients can testify to the quality and thoroughness of our workmanship. And once your interactive whiteboards are put in, we aren’t done yet. We’ll walk you and your team through comprehensive product orientation and operating procedures. We’ll take the time to answer your questions, too; we leave nothing to chance!</p>
              </OurSolutionListRightContent>
            </OurSolutionListRight>
          </OurSolutionList>
          <OurSolutionList>
            <OurSolutionListLeft>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </OurSolutionListLeft>
            <OurSolutionListRight>
              <OurSolutionListRightTitle className="h3">Site Survey & Professional Recommendations</OurSolutionListRightTitle>
              <OurSolutionListRightContent><p>Before <strong>WYN</strong> recommends any interactive whiteboard solution, we’ll start by seeking to learn more about your organization and listening to your goals. And one of the best ways for us to learn about your particular needs is to have a technician or two come out and do a physical walkthrough of your property and any intended whiteboard installation locations. After our survey, we’ll share our observations and insights based upon what we observe about your facility. Then we’ll be able to make some professional recommendations about the best interactive whiteboard solutions that will suit both your needs and your budget.</p></OurSolutionListRightContent>
            </OurSolutionListRight>
          </OurSolutionList>
          <OurSolutionList>
            <OurSolutionListLeft>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </OurSolutionListLeft>
            <OurSolutionListRight>
              <OurSolutionListRightTitle className="h3">Complete, No-Obligation Product Demos</OurSolutionListRightTitle>
              <OurSolutionListRightContent><p>Perhaps you’ve witnessed interactive whiteboards being used in other businesses or classrooms, but you’ve never had the opportunity to try out an interactive whiteboard yourself. No worries, <strong>WYN Technologies</strong> will be glad to give you that opportunity! If you’re new to interactive whiteboards, or just want to learn more about the features and benefits of a particular product, we can let you experience a full product demo. We’ll show you what all an interactive whiteboard can really do, and we’ll give you some good tips about how you can take full advantage of this powerful technology for your business or organization. We’re here to help!</p></OurSolutionListRightContent>
            </OurSolutionListRight>
          </OurSolutionList>
          <OurSolutionList>
            <OurSolutionListLeft>
              <StaticImage
                src="../images/placholder.jpg"
                quality={100}
                formats={["AUTO", "WEBP", "AVIF"]}
                alt="Industry"
                placeholder="blurred"
              />
            </OurSolutionListLeft>
            <OurSolutionListRight>
              <OurSolutionListRightTitle className="h3">Ongoing Support When You Need It</OurSolutionListRightTitle>
              <OurSolutionListRightContent>
                <p>Have you ever had a commercial or educational technology provider leave you feeling like you were all alone and on your own after they put in your equipment? That can be an incredibly frustrating experience! Thankfully, you won’t experience that feeling with <strong>WYN Technologies</strong>! We’ll be here whenever you may need us, and we’re also happy to offer a “service after the sale” contract to make sure everything is kept in good order and continues working well for you long into the future. In short, whenever you have an issue or problem, just give us a call; we’re available 24/7!</p>
              </OurSolutionListRightContent>
            </OurSolutionListRight>
          </OurSolutionList>


        </div>
      </Section>
      <BrandsWeTrust />
      <CustomerReiview />
      <CaseStudies />
      <GetStarted />
      <SectionBg bgBefore="#fff" bgAfter="#fff">
        <div className="container">
          <FaqRow>
            <FaqColumn>
              <FaqTitle className="h1">faq</FaqTitle>

              <TabListDesktop>
                {[
                  {
                    title: 'What will an interactive whiteboard system cost me?',
                  },
                  {
                    title: 'What can you do with an interactive whiteboard?',
                  },
                  {
                    title: 'What are some features & advantages of having an interactive whiteboard in the classroom?',
                  },
                  {
                    title: 'How can an interactive whiteboard benefit your business?',

                  }, {
                    title: 'What’s the difference between a smart board and an interactive whiteboard?',
                  },
                  {
                    title: 'Do I need to have a projector installed to be able to use an interactive whiteboard?',
                  }
                ].map((item, index) => (<TabListItem key={index} onClick={() => HandleTabs(index)} className={`${activeTab === index ? "active" : ""}`}><Text>{item.title} <PlusIcon /> <Icon className="icon"><DotteLineArrowIcon /></Icon></Text>
                </TabListItem>
                ))}
              </TabListDesktop>

            </FaqColumn>
            <FaqColumn>
              <TabContnet>
                {[
                  {
                    title: 'What will an interactive whiteboard system cost me?',
                    content: 'Depending upon the nature of your business or organization and what goals you have, we understand that cost can be a factor. But when it comes to interactive whiteboard price, it’s hard to give a simple, blanket quote as there are several factors that determine the total cost. A good place to start is by laying out some clear expectations of how you want to use your interactive whiteboard, and then use that to help build your project budget. Consider how many interactive whiteboards you’ll need and how much space each needs to cover. And in addition to the interactive whiteboards themselves, you’ll also need to factor in mounting hardware, software, wiring, and installation labor costs. WYN can help you walk through the entire process!'
                  },
                  {
                    title: 'What can you do with an interactive whiteboard?',
                    content: 'An interactive whiteboard is a giant technological step over old-school dry-erase whiteboards. Just like a static whiteboard, you can write notes on an interactive whiteboard in real-time. But it’s at that point where the similarities between whiteboards and interactive whiteboards end. Not only can you scribble notes or jot down ideas, but you can also use an interactive whiteboard to upload & display media, hold video chats, share files, poll viewers, students, or conference attendees, and much more. Extensive user training isn’t required, either; an interactive whiteboard really operates intuitively. Interactive whiteboards are powerful tools that are also incredibly easy to use.'
                  },
                  {
                    title: 'What are some features & advantages of having an interactive whiteboard in the classroom?',
                    content: 'In terms of great, integrated features for drawing students’ attention, it’s hard to beat an interactive whiteboard. It can run all sorts of programs and media files, and can be used to play videos, surf the internet, display multimedia presentations, and more. In addition, interactive whiteboards for education include other attractive built-in features like a spotlight, a magnifier, a screen shade, a pointer, and the ability to take screenshots of anything currently displayed on the board. Plus, interactive whiteboards aren’t just for the instructor. Students can be enabled, encouraged, and even required to make full use of the interactive whiteboard as part of their learning activities, as well.'
                  },
                  {
                    title: 'How can an interactive whiteboard benefit your business?',
                    content: 'There are many practical ways that an interactive whiteboard (or whiteboards) can benefit your business. Forget about those endless project meetings where nothing actually happens. An interactive whiteboard includes collaboration tools that can make your meetings truly dynamic. It’s simple to integrate Microsoft Office documents, Adobe Photoshop files, even CAD drawings. Obviously, an interactive whiteboard comes in handy for taking notes during a meeting, but you can also use it to upload and play media files, export content, deliver presentations, and more. It’s also a powerful tool for video conferencing, Zoom meetings, conducting wireless presentations, and other functionalities that will ultimately benefit your team and business.'
                  }, {
                    title: 'What’s the difference between a smart board and an interactive whiteboard?',
                    content: '<p>Smart boards are essentially just traditional whiteboards with ramped-up technical capabilities. Most smart board systems include three main components -- the whiteboard itself, a connected computer, and a separate digital projector. The projector is used to display inputs from the connected computer directly onto the whiteboard. One primary drawback of a smart board system is that only special pens can be used to interact with the touch-sensitive whiteboard. If you lose a pen, you’re basically out of luck until you can get a replacement.</p> <p>An interactive whiteboard works similarly, but doesn’t require as many separate components. The computer is typically built-in to the interactive whiteboard, and a separate digital projector isn’t needed. And what about the special pens one typically needs for a smart board? You won’t need any of those for an interactive whiteboard; you’re able to engage the touchscreen with any type of stylus, or even with your finger.</p>'
                  },
                  {
                    title: 'Do I need to have a projector installed to be able to use an interactive whiteboard?',
                    content: 'In a word, no. While older smart board technology required a physical board and a separate projector and computer, modern interactive whiteboards are completely integrated systems that need no external projection devices. An interactive whiteboard features a responsive touchscreen display with its internal light source. Essentially, it works just like a smartphone screen, only much bigger. External projectors aren’t necessary for an integrated interactive whiteboard system.'
                  }
                ].map((item, index) => {
                  return (
                    <TabContentItem key={index} className={activeTab === index ? 'tab-item active' : 'tab-item'} >
                      <TabListMobile> <TabListItem key={index} onClick={() => HandleTabs(index)} className={`${activeTab === index ? "active" : ""}`}><Text>{item.title}<PlusIcon /> <Icon className="icon"><DotteLineArrowIcon /></Icon></Text>
                      </TabListItem></TabListMobile>
                      <TabContentinner className="tab-content-inner">
                      <div dangerouslySetInnerHTML={{ __html: item.content }}></div>
                      </TabContentinner>
                    </TabContentItem>
                  )
                })}
              </TabContnet>
            </FaqColumn>
          </FaqRow>
        </div>
      </SectionBg>
      <Section pt="80px" mpt="40px" bgColor="#F8F8F8" pb="50px" mpb="40px" xxpt="100px" xxpb="100px">
        <div className="container">
          <SectionHeader>
            <Title className="h1">Latest Articles & Blogs</Title>
          </SectionHeader>
          <ComingSoon textAlign="center">Coming Soon...</ComingSoon>
          {/* <Blogs>
            <BlogItems>
              <Card>
                <Date>24 June, 2020</Date>
                <CardTitle className="h6">We were recently featured on the local WXII news channel with regard to Thermal Temperature Monitoring.</CardTitle>
                <Tags to="/">Surveillance System</Tags> <Tags to="/">Thermal Monitoring</Tags>
                <CardImg>
                  <StaticImage
                    src="../../images/article-1.jpg"
                    quality={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Letast Article"
                    placeholder="blurred"
                  />
                </CardImg>
                <Link to="/"><SecondaryLinkButton text="Learn More" /></Link>
              </Card>
            </BlogItems>
            <BlogItems>
              <Card>
                <Date>24 June, 2020</Date>
                <CardTitle className="h6">We were recently featured on the local WXII news channel with regard to Thermal Temperature Monitoring.</CardTitle>
                <Tags to="/">Surveillance System</Tags> <Tags to="/">Thermal Monitoring</Tags>
                <CardImg>
                  <StaticImage
                    src="../../images/article-1.jpg"
                    quality={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Letast Article"
                    placeholder="blurred"
                  />
                </CardImg>
                <Link to="/"><SecondaryLinkButton text="Learn More" /></Link>
              </Card>
            </BlogItems>
            <BlogItems>
              <Card>
                <Date>24 June, 2020</Date>
                <CardTitle className="h6">We were recently featured on the local WXII news channel with regard to Thermal Temperature Monitoring.</CardTitle>
                <Tags to="/">Surveillance System</Tags> <Tags to="/">Thermal Monitoring</Tags>
                <CardImg>
                  <StaticImage
                    src="../../images/article-1.jpg"
                    quality={100}
                    formats={["AUTO", "WEBP", "AVIF"]}
                    alt="Letast Article"
                    placeholder="blurred"
                  />
                </CardImg>
                <Link to="/"><SecondaryLinkButton text="Learn More" /></Link>
              </Card>
            </BlogItems>
          </Blogs> */}
        </div>
      </Section>
    </Layout>
  )
}

export default ServicePage
